
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ItemList extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  public titleText: string;

  @Prop({
    type: String,
    required: false,
  })
  public infoText: string;
}
