
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ItemList extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  public value: string[];

  @Prop({
    type: String,
    required: false,
  })
  public validatorRules: string;

  @Prop({
    type: String,
    required: false,
  })
  public itemType: string;

  @Prop({
    type: String,
    required: false,
  })
  public label: string;

  @Prop({
    type: String,
    required: true,
  })
  public addElementText: string;

  // Manually trigger form validation on update so that external updates of the values get picked up
  mounted() {
    if (this.$refs.elementInput) {
      for (const value of (this.$refs.elementInput as unknown) as {
        value: number;
        $el: Element;
      }[]) {
        if (value.value) {
          value.$el.dispatchEvent(new CustomEvent('blur'));
        }
      }
    }
  }

  public handleAddElement(): void {
    if (!this.value) {
      this.value = [];
    }

    if (this.value[this.value.length - 1] != '') {
      this.value.push('');
      this.$emit('input', this.value);
    }
  }

  public handleRemoveElement(i): void {
    this.value.splice(i, 1);
    this.$emit('input', this.value);
  }

  public emitInput(): void {
    this.$emit('input', this.value);
  }
}
