
import { Component, Prop, Vue } from 'vue-property-decorator';
import ItemList from '@/components/shared/forms/ItemList.vue';
import FormElementHeader from '@/components/shared/forms/FormElementHeader.vue';

@Component({
  components: {
    FormElementHeader,
    ItemList,
  },
})
export default class UrlList extends Vue {
  @Prop({
    type: String,
    required: false,
  })
  public titleText: string;

  @Prop({
    type: String,
    required: false,
  })
  public infoText: string;

  @Prop({
    type: String,
    required: false,
  })
  public buttonCopy: string;

  @Prop({
    type: Array,
    required: true,
  })
  public value: string[];

  public handleChange(value: string[]): void {
    this.$emit('input', value);
  }
}
